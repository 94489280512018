import type { TFunction } from '!/i18n'
import type {
  TVShowsPlaylist,
  TvShowEpisode,
  TvShowSeasonWithEpisodes,
} from '@setplex/tria-api'
import { produce } from 'immer'
import {
  extendMediaItemByLabelPriceTvShowEpisode,
  extendMediaItemByLabelPriceTvShowSeason,
} from '~/shared/tools'

const formatSeasonEpisode = (episode: TvShowEpisode) => ({
  ...episode,
  labelPrice: extendMediaItemByLabelPriceTvShowEpisode(episode).labelPrice,
})

const formatSeason = ({
  season,
  t,
}: {
  season: TvShowSeasonWithEpisodes
  t: TFunction
}) => {
  season.title = t('episode_count', {
    count: season.episodesAmount,
  })
  season.labelPrice = extendMediaItemByLabelPriceTvShowSeason(season).labelPrice
  season.episodes = season.episodes.map(formatSeasonEpisode)
}

export const playlistFormatter = produce(
  (draft: TVShowsPlaylist, t: TFunction) => {
    draft?.seasonsWithEpisodes?.forEach((season) => formatSeason({ season, t }))
  }
)
