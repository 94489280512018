import { model as router } from '!/router'
import { createEvent, restore, sample } from 'effector'
import { aye } from '~/shared/helpers'
import type { SignInToWatchItem } from './index.h'

export const init = createEvent()

export const openSignInToWatchPopup = createEvent<SignInToWatchItem>()
export const $signInToWatchItem = restore<SignInToWatchItem | null>(
  openSignInToWatchPopup,
  null
)

export const signInToWatch = createEvent()
export const closeSignInToWatchPopup = createEvent()

sample({
  clock: signInToWatch,
  source: $signInToWatchItem,
  filter: aye,
  fn: (item) => `/sign-in/~${item!.redirect}`,
  target: [router.navigatePush, $signInToWatchItem.reinit],
})

sample({
  clock: closeSignInToWatchPopup,
  target: $signInToWatchItem.reinit,
})
