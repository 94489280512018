import type { ILocale } from '../../interfaces/i18n'

export const translationsFromNoraFormat = (
  content: Array<ILocale>
): Array<Pick<ILocale, 'locale' | 'translations'>> => {
  return content.map(({ locale, translations }: ILocale) => ({
    locale,
    translations,
  }))
}
